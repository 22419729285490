/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    h2: "h2",
    ol: "ol",
    li: "li",
    img: "img"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "hearcoms-clinic-in-a-box-creating-simplicity-through-telehealth",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcoms-clinic-in-a-box-creating-simplicity-through-telehealth",
    "aria-label": "hearcoms clinic in a box creating simplicity through telehealth permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com’s Clinic-in-a-Box", React.createElement("sup", null, "®"), ": Creating Simplicity Through Telehealth"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With hear.com’s innovative ", React.createElement(_components.strong, null, "Clinic-in-a-Box"), React.createElement("sup", null, "®"), " that’s mailed directly to your home, you now have access to top-of-the-line hearing care and licensed hearing specialists through safe and convenient video appointments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-hearcoms-clinic-in-a-box",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-hearcoms-clinic-in-a-box",
    "aria-label": "what is hearcoms clinic in a box permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is hear.com’s Clinic-in-a-Box?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com’s ", React.createElement(_components.strong, null, "Clinic-in-a-Box"), " is the newest, coolest thing in hearing care and telehealth medicine. You no longer have to leave your house or make multiple visits to a clinic for quality hearing care, because we can now do it all online for you. Here’s how it works in 3 simple steps."), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After first speaking to one of our consultants about your hearing loss and securing your 45-day hearing aid trial, we’ll mail you a box of goodies — known as the ", React.createElement(_components.strong, null, "Clinic-in-a-Box"), ". You’ll have everything you need to meet with one of our hearing specialists: a computer tablet that’s programmed for your appointment, easy-to-use hearing test equipment and of course your new, nearly invisible hearing aids."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You’ll receive a courtesy call from our support team before the appointment to walk you through how to use everything and set up and log in to the tablet. (Don’t worry, it’s easy!) They’ll also answer any questions you may have to make sure you’re 100% comfortable."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "On the day of your appointment, you’ll log in to the tablet and meet via video with one of our top hearing care specialists. They’ll walk you through everything, including the hearing test, and fit you with hearing aids right then and there. They’ll program your devices right on the spot to your specific needs, so you can start wearing and using them immediately."), "\n"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/telehealth.jpg",
    alt: "Lady using telehealth",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-a-telehealth-program-benefits-those-with-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-a-telehealth-program-benefits-those-with-hearing-loss",
    "aria-label": "how a telehealth program benefits those with hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How a telehealth program benefits those with hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Teleaudiology (the formal name for telehealth hearing care) experienced a boom in popularity in 2020; rightfully so with all the benefits it offers to people with hearing loss. Convenience and safety are the most popular reasons why more than 60 percent of Americans have scheduled a telehealth appointment in 2022!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many people in the U.S. don’t have convenient access or transportation to hearing care centers. Oftentimes they’re located more than 60 miles away and cannot drive themselves to and from frequent appointments, which are often needed for hearing aid fittings and adjustments. And it’s no secret that people were and are still concerned about going to the doctor and being exposed to illnesses."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With hear.com’s ", React.createElement(_components.strong, null, "Clinic-in-a-Box"), " and teleaudiology service, those worries go out the window because you never have to leave your home to get the same high quality hearing care as you would at an in-person clinic. You can get your hearing aids fitted and adjusted via video, and from the comfort of your couch."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com customers are actually experiencing better outcomes through teleaudiology, too. Because you’re hearing all the normal daily sounds around you during your appointment at home, you can tell your specialist during the video appointment what sounds good and what doesn’t; what you like and don’t like. They’ll then program your hearing aids right then and there to your everyday needs and preferences."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Just like with anything new, there will always be an adjustment period with hearing aids, but the first day is guaranteed to be better after a teleaudiology appointment because your devices are programmed to where you spend most of your time — at home — rather than in a soundproof booth at a hearing clinic."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-makes-hearing-care-more-accessible",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-makes-hearing-care-more-accessible",
    "aria-label": "hearcom makes hearing care more accessible permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com makes hearing care more accessible"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We believe everyone should hear well to live well. It’s been our mission since day one to make hearing care more accessible and affordable to everyone around the world. Teleaudiology and our ", React.createElement(_components.strong, null, "Clinic-in-a-Box"), " allows us to more easily reach those who would otherwise put off doing something about their hearing loss."), "\n", React.createElement(LandingPageCta, {
    copy: "Schedule your own clinic-in-a-box now!",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
